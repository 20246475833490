import {
  Box,
  Center,
  Flex,
  Image,
  SimpleGrid,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useState } from 'react';
import home from '../assets/images/home.png';
import Loading from '../components/Loading';
import Navbar from '../components/header/Navbar';
import ModalImage from 'react-modal-image';

export default function Dashboard() {
  const [loading, setLoading] = useState(false);

  if (loading) {
    return <Loading />;
  }

  return (
    <Box>
      <Navbar />

      <Center>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          variants={{
            visible: { opacity: 1, scale: 1 },
            hidden: { opacity: 0, scale: 0 },
          }}
        >
          <Box
            maxW="1000px"
            w="100%"
            boxShadow="xl"
            p={10}
            m={{ md: 10 }}
            borderRadius="xl"
            backgroundColor={{ base: 'transparent', md: 'whiteAlpha.900' }}
          >
            <SimpleGrid columns={[1, 1, 2, 2]} spacingY={4}>
              <Center>
                <ModalImage
                  small={home}
                  large={home}
                  maxW="400px"
                  imageBackgroundColor="transparent"
                  hideDownload={true}
                  hideZoom={true}
                />
              </Center>
              <Center>
                <Text
                  backgroundColor={{
                    base: 'whiteAlpha.900',
                    md: 'transparent',
                  }}
                  borderRadius="xl"
                  padding={{ base: 7, md: 10 }}
                >
                  Umfangreiches Fachwissen, ein geschicktes Händchen und vor
                  allem die Liebe zum Tier bilden die Basis meiner Tätigkeit.
                  <br />
                  <br /> Seit 2005 betreibe ich mein Hunde-Studio im
                  niederbayerischen Zwiesel
                  <br />
                  <br /> Bei mir ist ihr Liebling in besten Händen.
                </Text>
              </Center>
            </SimpleGrid>
          </Box>
        </motion.div>
      </Center>
    </Box>
  );
}
