import {
  Box,
  Center,
  Container,
  Flex,
  SimpleGrid,
  Spacer,
  Text,
} from '@chakra-ui/layout';
import Navbar from '../components/header/Navbar';
import { motion } from 'framer-motion';
import { Image } from '@chakra-ui/image';
import home from '../assets/images/home.png';
import leistungen from '../assets/images/leistungen.jpeg';
import ModalImage from 'react-modal-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScissors } from '@fortawesome/free-solid-svg-icons';
import WorkItem from '../components/work';

export default function Work() {
  return (
    <Box>
      <Navbar />
      <Center>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          variants={{
            visible: { opacity: 1, scale: 1 },
            hidden: { opacity: 0, scale: 0 },
          }}
        >
          <Box
            maxW="1000px"
            width="100%"
            boxShadow="xl"
            p={10}
            m={10}
            borderRadius="xl"
            bgColor="whiteAlpha.900"
          >
            <Text fontSize={24}>
              Leistungen
              <Container
                height="5px"
                width="100%"
                backgroundColor="grey"
                borderRadius="xl"
              />
            </Text>
            <SimpleGrid columns={[1, 2, 2, 2]} gap={[8, 3]} mt={5}>
              <Center>
                <Box textAlign="center">
                  <Text m={2}>Baden (nur kleine Hunde)</Text>
                  <Text m={2}>Fönen per Hand</Text>
                  <Text m={2}>Entfilzen</Text>
                  <Text m={2}>Schneiden</Text>
                  <Text m={2}>Scheren</Text>
                  <Text m={2}>Augen- und Ohrenpflege</Text>
                  <Text fontWeight="bold" m={6}>
                    Extra`s : Katzenpflege
                  </Text>
                </Box>
              </Center>
              <Center>
                <Box maxW="350px">
                  <ModalImage
                    small={leistungen}
                    large={leistungen}
                    imageBackgroundColor="transparent"
                    hideDownload={true}
                    hideZoom={true}
                  />
                </Box>
              </Center>
            </SimpleGrid>
          </Box>
        </motion.div>
      </Center>
    </Box>
  );
}
