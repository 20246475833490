import { Box, Center, Container, Text, SimpleGrid } from '@chakra-ui/layout';
import { motion } from 'framer-motion';
import Navbar from '../components/header/Navbar';
import ModalImage from 'react-modal-image';

import gallerie1 from '../assets/images/gallerie/gallerie1.png';
import gallerie2 from '../assets/images/gallerie/gallerie2.jpeg';
import gallerie3 from '../assets/images/gallerie/gallerie3.jpeg';
import gallerie4 from '../assets/images/gallerie/gallerie4.jpeg';
import gallerie5 from '../assets/images/gallerie/gallerie5.jpeg';
import gallerie6 from '../assets/images/gallerie/gallerie6.jpeg';
import gallerie7 from '../assets/images/gallerie/gallerie7.jpeg';
import gallerie8 from '../assets/images/gallerie/gallerie8.jpeg';
import gallerie9 from '../assets/images/gallerie/gallerie9.jpeg';
import gallerie10 from '../assets/images/gallerie/gallerie10.jpeg';
import gallerie11 from '../assets/images/gallerie/gallerie11.jpeg';
import gallerie13 from '../assets/images/gallerie/gallerie13.jpeg';
import gallerie14 from '../assets/images/gallerie/gallerie14.jpeg';
import gallerie15 from '../assets/images/gallerie/gallerie15.jpeg';
import gallerie16 from '../assets/images/gallerie/gallerie16.jpeg';
import gallerie17 from '../assets/images/gallerie/gallerie17.jpeg';
import gallerie18 from '../assets/images/gallerie/gallerie18.jpeg';
import gallerie19 from '../assets/images/gallerie/gallerie19.jpeg';
import gallerie20 from '../assets/images/gallerie/gallerie20.jpeg';
import gallerie21 from '../assets/images/gallerie/gallerie21.jpeg';
import gallerie22 from '../assets/images/gallerie/gallerie22.jpeg';
import gallerie23 from '../assets/images/gallerie/gallerie23.jpeg';
import gallerie24 from '../assets/images/gallerie/gallerie24.jpeg';
import gallerie25 from '../assets/images/gallerie/gallerie25.jpeg';
import gallerie26 from '../assets/images/gallerie/gallerie26.jpeg';
import gallerie27 from '../assets/images/gallerie/gallerie27.jpeg';
import gallerie28 from '../assets/images/gallerie/gallerie28.jpeg';
import gallerie29 from '../assets/images/gallerie/gallerie29.jpeg';
import gallerie30 from '../assets/images/gallerie/gallerie30.jpeg';
import gallerie31 from '../assets/images/gallerie/gallerie31.jpeg';
import gallerie32 from '../assets/images/gallerie/gallerie32.jpeg';
import gallerie33 from '../assets/images/gallerie/gallerie33.jpeg';
import gallerie34 from '../assets/images/gallerie/gallerie34.jpeg';
import gallerie35 from '../assets/images/gallerie/gallerie35.jpeg';
import gallerie36 from '../assets/images/gallerie/gallerie36.jpeg';
import gallerie37 from '../assets/images/gallerie/gallerie37.jpeg';
import gallerie38 from '../assets/images/gallerie/gallerie38.jpeg';
import gallerie39 from '../assets/images/gallerie/gallerie39.jpeg';
import gallerie40 from '../assets/images/gallerie/gallerie40.jpeg';
import gallerie41 from '../assets/images/gallerie/gallerie41.jpeg';
import gallerie42 from '../assets/images/gallerie/gallerie42.jpeg';
import gallerie43 from '../assets/images/gallerie/gallerie43.jpeg';
import gallerie44 from '../assets/images/gallerie/gallerie44.jpeg';
import gallerie45 from '../assets/images/gallerie/gallerie45.jpeg';
import gallerie46 from '../assets/images/gallerie/gallerie46.jpeg';
import gallerie47 from '../assets/images/gallerie/gallerie47.jpeg';
import gallerie48 from '../assets/images/gallerie/gallerie48.jpeg';
import gallerie49 from '../assets/images/gallerie/gallerie49.jpeg';
import gallerie50 from '../assets/images/gallerie/gallerie50.jpeg';
import gallerie51 from '../assets/images/gallerie/gallerie51.jpeg';
import gallerie52 from '../assets/images/gallerie/gallerie52.jpeg';
import gallerie53 from '../assets/images/gallerie/gallerie53.jpeg';
import gallerie54 from '../assets/images/gallerie/gallerie54.jpeg';
import gallerie55 from '../assets/images/gallerie/gallerie55.jpeg';
import gallerie56 from '../assets/images/gallerie/gallerie56.jpeg';
import gallerie57 from '../assets/images/gallerie/gallerie57.jpeg';
import gallerie58 from '../assets/images/gallerie/gallerie58.jpeg';
import gallerie59 from '../assets/images/gallerie/gallerie59.jpeg';
import gallerie60 from '../assets/images/gallerie/gallerie60.jpeg';
import gallerie61 from '../assets/images/gallerie/gallerie61.jpeg';
import gallerie62 from '../assets/images/gallerie/gallerie62.jpeg';
import gallerie63 from '../assets/images/gallerie/gallerie63.jpeg';
import gallerie64 from '../assets/images/gallerie/gallerie64.jpeg';
import gallerie65 from '../assets/images/gallerie/gallerie65.jpeg';
import gallerie66 from '../assets/images/gallerie/gallerie66.jpeg';
import gallerie67 from '../assets/images/gallerie/gallerie67.jpeg';
import gallerie68 from '../assets/images/gallerie/gallerie68.jpeg';
import gallerie69 from '../assets/images/gallerie/gallerie69.jpeg';
import gallerie70 from '../assets/images/gallerie/gallerie70.jpeg';

import { Modal } from '@chakra-ui/react';

export default function Gallery() {
  const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

  const images = [
    gallerie1,
    gallerie2,
    gallerie3,
    gallerie4,
    gallerie5,
    gallerie6,
    gallerie7,
    gallerie8,
    gallerie9,
    gallerie10,
    gallerie11,
    gallerie13,
    gallerie14,
    gallerie15,
    gallerie16,
    gallerie17,
    gallerie18,
    gallerie19,
    gallerie20,
    gallerie21,
    gallerie22,
    gallerie23,
    gallerie24,
    gallerie25,
    gallerie26,
    gallerie27,
    gallerie28,
    gallerie29,
    gallerie30,
    gallerie31,
    gallerie32,
    gallerie33,
    gallerie34,
    gallerie35,
    gallerie36,
    gallerie37,
    gallerie38,
    gallerie39,
    gallerie40,
    gallerie41,
    gallerie42,
    gallerie43,
    gallerie44,
    gallerie45,
    gallerie46,
    gallerie47,
    gallerie48,
    gallerie49,
    gallerie50,
    gallerie51,
    gallerie52,
    gallerie53,
    gallerie54,
    gallerie55,
    gallerie56,
    gallerie57,
    gallerie58,
    gallerie59,
    gallerie60,
    gallerie61,
    gallerie62,
    gallerie63,
    gallerie64,
    gallerie65,
    gallerie66,
    gallerie67,
    gallerie68,
    gallerie69,
    gallerie70
  ];

  return (
    <Box>
      <Navbar />
      <Center>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          variants={{
            visible: { opacity: 1, scale: 1 },
            hidden: { opacity: 0, scale: 0 },
          }}
        >
          <Box
            maxW="1000px"
            boxShadow="xl"
            p={{ base: 0, sm: 10 }}
            m={{ base: 0, sm: 10 }}
            borderRadius="xl"
            bgColor="whiteAlpha.900"
          >
            <Text mb={10} fontSize={24}>
              Gallerie
              <Container
                height="5px"
                width="100%"
                backgroundColor="grey"
                borderRadius="xl"
              />
            </Text>
            <SimpleGrid columns={[2, 2, 3, 4]} gap={[4, 4]}>
              {images.map(v => (
                <ModalImage
                  small={v}
                  large={v}
                  imageBackgroundColor="transparent"
                  hideDownload={true}
                  hideZoom={true}
                />
              ))}
            </SimpleGrid>
          </Box>
        </motion.div>
      </Center>
    </Box>
  );
}
