import {
  Box,
  Button,
  Center,
  ChakraProvider,
  Container,
  Flex,
  Link,
  Text,
  extendTheme,
} from '@chakra-ui/react';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import background from '../assets/images/background.jpeg';
import Aboutme from './AboutMe';
import Dashboard from './Dashboard';
import Gallery from './Gallery';
import Work from './Work';
import './App.css';
import Contact from './Contact';
import LovedOnes from './LovedOnes';
import Disclaimer from './Disclaimer';
import Impressum from './Impressum';

const theme = extendTheme({
  colors: {
    bg: '#1A202C',
    secondaryLight: '#A0AEC0',
    secondaryMedium: '#718096',
    secondaryDark: '#2A4365',
  },
  styles: {
    global: {
      'html, body': {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 700,
      },
    },
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box minHeight="100vh" bgImage={{ sm: background }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/aboutme" element={<Aboutme />} />
            <Route path="/leistungen" element={<Work />} />
            <Route path="/lieblinge" element={<LovedOnes />} />
            <Route path="/gallerie" element={<Gallery />} />
            <Route path="/kontakt" element={<Contact />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/datenschutz" element={<Disclaimer />} />
          </Routes>
        </BrowserRouter>
        <Center>
          <Flex alignItems="center">
            <Link marginRight={10} href="/impressum">
              <Button variant="ghost">Impressum</Button>
            </Link>
            <Link marginLeft={10} href="/datenschutz">
              <Button variant="ghost">Datenschutz</Button>
            </Link>
          </Flex>
        </Center>
      </Box>
    </ChakraProvider>
  );
}

export default App;
