import { Center, Spinner } from '@chakra-ui/react';

export default function Loading() {
  return (
    <Center h="100vh">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="bg"
        color="secondaryLight"
        size="xl"
      />
    </Center>
  );
}
