import {
  Box,
  Center,
  Container,
  Flex,
  SimpleGrid,
  Heading,
  Text,
} from '@chakra-ui/layout';
import Navbar from '../components/header/Navbar';
import { motion } from 'framer-motion';
import { Image } from '@chakra-ui/image';
import home from '../assets/images/home.png';
import rico1 from '../assets/images/rico1.jpeg';
import rico2 from '../assets/images/rico2.jpeg';
import don1 from '../assets/images/don1.jpeg';
import don2 from '../assets/images/don2.jpeg';
import darius1 from '../assets/images/darius1.jpeg';
import darius2 from '../assets/images/darius2.jpeg';
import donna1 from '../assets/images/donna1.jpeg';
import donna2 from '../assets/images/donna2.jpeg';
import daisy1 from '../assets/images/daisy.png';
import daisy2 from '../assets/images/daisy2.png';
import sissi from '../assets/images/sissi.jpeg';
import pumuckel from '../assets/images/gallerie/gallerie25.jpeg';
import moritz from '../assets/images/gallerie/moritz.png';
import sissi2 from '../assets/images/gallerie/sissimbaby.png';

import ModalImage from 'react-modal-image';

export default function LovedOnes() {
  return (
    <Box>
      <Navbar />
      <Center>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          variants={{
            visible: { opacity: 1, scale: 1 },
            hidden: { opacity: 0, scale: 0 },
          }}
        >
          <Box
            maxW="1000px"
            boxShadow="xl"
            p={10}
            m={10}
            borderRadius="xl"
            bgColor="whiteAlpha.900"
          >
            <Text fontSize={24}>
              Meine Lieblinge
              <Container
                height="5px"
                width="100%"
                backgroundColor="grey"
                borderRadius="xl"
              />
            </Text>
            <Center>
              <Text margin={10}>
                Im Gedenken an meinen über alles geliebten und einzigartigen
                Rico... <br />
                <br /> ...Eine alte indianische Überlieferung besagt, dass die
                Hunde nach ihrem Tod in einem weiten Land am Roten Fluss leben
                und dort auf ihren Menschen warten, um ihnen eines Tages den Weg
                über den Fluss in die ewigen Jagdgründe zu zeigen. Denn ohne
                einen solchen Führer könne der Mensch den Weg dorthin nicht
                finden.
              </Text>
            </Center>
            <Heading fontSize={{ base: 16, md: 24 }} mb={5}>
              Rico
              <Container
                height="5px"
                width="100%"
                backgroundColor="grey"
                borderRadius="xl"
              />
            </Heading>
            <Center maxWidth="600px">
              <SimpleGrid columns={[1, 1, 2, 2]} gap={[4, 12]}>
                <ModalImage
                  small={rico1}
                  large={rico1}
                  imageBackgroundColor="transparent"
                  hideDownload={true}
                  hideZoom={true}
                />
                <ModalImage
                  small={rico2}
                  large={rico2}
                  imageBackgroundColor="transparent"
                  hideDownload={true}
                  hideZoom={true}
                />
              </SimpleGrid>
            </Center>
            <Heading fontSize={{ base: 16, md: 24 }} mb={5} mt={5}>
              Don
              <Container
                height="5px"
                width="100%"
                backgroundColor="grey"
                borderRadius="xl"
              />
            </Heading>
            <Center maxWidth="600px">
              <SimpleGrid columns={[1, 1, 2, 2]} gap={[4, 12]}>
                <ModalImage
                  small={don1}
                  large={don1}
                  imageBackgroundColor="transparent"
                  hideDownload={true}
                  hideZoom={true}
                />
                <ModalImage
                  small={don2}
                  large={don2}
                  imageBackgroundColor="transparent"
                  hideDownload={true}
                  hideZoom={true}
                />
              </SimpleGrid>
            </Center>
            <Heading fontSize={{ base: 16, md: 24 }} mb={5} mt={5}>
              Darius
              <Container
                height="5px"
                width="100%"
                backgroundColor="grey"
                borderRadius="xl"
              />
            </Heading>
            <Center maxWidth="600px">
              <SimpleGrid columns={[1, 1, 2, 2]} gap={[4, 12]}>
                <ModalImage
                  small={darius1}
                  large={darius1}
                  imageBackgroundColor="transparent"
                  hideDownload={true}
                  hideZoom={true}
                />
                <ModalImage
                  small={darius2}
                  large={darius2}
                  imageBackgroundColor="transparent"
                  hideDownload={true}
                  hideZoom={true}
                />
              </SimpleGrid>
            </Center>
            <Heading fontSize={{ base: 16, md: 24 }} mb={5} mt={5}>
              Donna
              <Container
                height="5px"
                width="100%"
                backgroundColor="grey"
                borderRadius="xl"
              />
            </Heading>
            <Center maxWidth="600px">
              <SimpleGrid columns={[1, 1, 2, 2]} gap={[4, 12]}>
                <ModalImage
                  small={donna1}
                  large={donna1}
                  imageBackgroundColor="transparent"
                  hideDownload={true}
                  hideZoom={true}
                />
                <ModalImage
                  small={donna2}
                  large={donna2}
                  imageBackgroundColor="transparent"
                  hideDownload={true}
                  hideZoom={true}
                />
              </SimpleGrid>
            </Center>
            <Heading fontSize={{ base: 16, md: 24 }} mb={5} mt={5}>
              Meine Katzen
              <Container
                height="5px"
                width="100%"
                backgroundColor="grey"
                borderRadius="xl"
              />
            </Heading>
            <Center>
              <SimpleGrid columns={[1, 1, 2, 3]} gap={[4, 12]}>
                <ModalImage
                  small={daisy1}
                  large={daisy1}
                  imageBackgroundColor="transparent"
                  hideDownload={true}
                  hideZoom={true}
                />
                <ModalImage
                  small={daisy2}
                  large={daisy2}
                  imageBackgroundColor="transparent"
                  hideDownload={true}
                  hideZoom={true}
                />
                <ModalImage
                  small={sissi}
                  large={sissi}
                  imageBackgroundColor="transparent"
                  hideDownload={true}
                  hideZoom={true}
                />
                <ModalImage
                  small={pumuckel}
                  large={pumuckel}
                  imageBackgroundColor="transparent"
                  hideDownload={true}
                  hideZoom={true}
                />
                <ModalImage
                  small={moritz}
                  large={moritz}
                  imageBackgroundColor="transparent"
                  hideDownload={true}
                  hideZoom={true}
                />
                <ModalImage
                  small={sissi2}
                  large={sissi2}
                  imageBackgroundColor="transparent"
                  hideDownload={true}
                  hideZoom={true}
                />
              </SimpleGrid>
            </Center>
          </Box>
        </motion.div>
      </Center>
    </Box>
  );
}
