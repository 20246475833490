import {
  Box,
  Center,
  Container,
  Flex,
  SimpleGrid,
  Spacer,
  Text,
} from '@chakra-ui/layout';
import Navbar from '../components/header/Navbar';
import { motion } from 'framer-motion';
import { Image } from '@chakra-ui/image';
import home from '../assets/images/home.png';
import ModalImage from 'react-modal-image';

export default function Contact() {
  return (
    <Box>
      <Navbar />
      <Center>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          variants={{
            visible: { opacity: 1, scale: 1 },
            hidden: { opacity: 0, scale: 0 },
          }}
        >
          <Box
            maxW="1000px"
            boxShadow="xl"
            p={10}
            m={10}
            borderRadius="xl"
            bgColor="whiteAlpha.900"
          >
            <Text fontSize={24}>
              Kontakt
              <Container
                height="5px"
                width="100%"
                backgroundColor="grey"
                borderRadius="xl"
              />
            </Text>
            <Center>
              <Text margin={10} fontWeight={'normal'}>
                Michaela Pfannenstiel <br /> Frauenauer Straße 36 a <br />
                94227 Zwiesel <br />
                <br />
                <b>E-Mail:</b> micha@jacky-bitt-box.de (Bitte beachten Sie, dass
                E-Mails nicht immer täglich abgerufen werden.) <br />
                <br />
                <b>
                  Parkmöglichkeit direkt vorm Haus oder gegenüber. <br />
                  <br /> Termine nur nach Voranmeldung ! <br />
                  Terminvereinbarung Telefonisch unter 09922 - 4803 Mobil 0171 -
                  37 85 111
                </b>
              </Text>

              <iframe
                width="600"
                height="450"
                Loading="lazy"
                src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJX4Y1SlUhdUcRs3O-NXEjgZ8&key=AIzaSyBPdvbNd_0RqdOBq4B0wTiOaKmy8JlYRcs&zoom=18"
              ></iframe>
            </Center>
          </Box>
        </motion.div>
      </Center>
    </Box>
  );
}
