import {
  Box,
  Center,
  Container,
  Flex,
  SimpleGrid,
  Spacer,
  Text,
} from '@chakra-ui/layout';
import Navbar from '../components/header/Navbar';
import { motion } from 'framer-motion';
import { Image } from '@chakra-ui/image';
import home from '../assets/images/home.png';
import aboutme1 from '../assets/images/uebermich1.png';
import aboutme2 from '../assets/images/uebermich2.png';
import aboutme3 from '../assets/images/uebermich3.png';
import diplom1 from '../assets/images/diplom2.jpeg';
import diplom2 from '../assets/images/diplom3.jpeg';
import ModalImage from 'react-modal-image';

export default function Aboutme() {
  return (
    <Box>
      <Navbar />
      <Center>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          variants={{
            visible: { opacity: 1, scale: 1 },
            hidden: { opacity: 0, scale: 0 },
          }}
        >
          <Box
            maxW="1000px"
            boxShadow="xl"
            p={10}
            m={10}
            borderRadius="xl"
            bgColor="whiteAlpha.900"
          >
            <Text fontSize={24}>
              Über mich
              <Container
                height="5px"
                width="100%"
                backgroundColor="grey"
                borderRadius="xl"
              />
            </Text>
            <Center>
              <Text margin={10}>
                Schon als kleines Mädchen war mein Herzenswunsch ein eigener
                Hund. Dieser Wunsch ging allerdings erst im Erwachsenalter in
                Erfüllung, bis dahin waren Goldhamster, Zwergkaninchen, Katzen
                und zuletzt vier Perser-Schönheiten meine Lieblinge und diese
                wurden nach allen Regeln der Kunst gehegt und gepflegt. lm Jahr
                2000 war es dann endlich so weit - mein erster Hund,
                Riesenschnauzer RICO, hielt Einzug in unser Zuhause, ihm folgte
                Schäferhund DON und Boxer DARIUS. Mittlerweile hab ich wieder
                Hund und Katze. Das erste Hundemädchen Boxer "Donna" und
                "Findling", Kater Pumuckl.
                <br />
                <br /> Am 1. März 2005 eröffnete ich mein Hunde-Studio
                "VierPfoten”,nachdem ich meine Ausbildung im Rotthaler
                Hundezentrum mit Diplom absolviert hatte. Mit viel Geduld, Liebe
                zum Tier und mehrjähriger Erfahrung im Ausstellungsbereich kann
                ich heute meine "vierbeinige Kundschaft" pflegen und
                verschönern.
              </Text>
            </Center>
            <SimpleGrid columns={[1, 1, 2, 3]} gap={[4, 8]}>
              <ModalImage
                small={aboutme1}
                large={aboutme1}
                maxW="400px"
                imageBackgroundColor="transparent"
                hideDownload={true}
                hideZoom={true}
              />
              <ModalImage
                small={aboutme2}
                large={aboutme2}
                maxW="400px"
                imageBackgroundColor="transparent"
                hideDownload={true}
                hideZoom={true}
              />
              <ModalImage
                small={aboutme3}
                large={aboutme3}
                maxW="400px"
                imageBackgroundColor="transparent"
                hideDownload={true}
                hideZoom={true}
              />
            </SimpleGrid>
            <SimpleGrid columns={[1, 1, 2, 2]} gap={[16, 8]} mt={10}>
              <Container>
                <Text w="180px">
                  Leistungsprüfungen
                  <Container
                    height="5px"
                    width="100%"
                    backgroundColor="grey"
                    borderRadius="xl"
                  />
                </Text>
                <Text mt={5}>
                  02.06.2002 - Begleithundeprüfung/Verhaltenstest
                </Text>
                <Text>23.03.2003 - Ausdauerprüfung</Text>
                <Text>01.06.2003 - IPO 1</Text>
                <Text>14.07.2003 - Körung Körklasse 1</Text>
                <Text>02.10.2004 - SchH 1</Text>
                <Text>16.10.2005 - SchH 2</Text>
              </Container>
              <Container>
                <Text w="140px">
                  Ausstellungen
                  <Container
                    height="5px"
                    width="100%"
                    backgroundColor="grey"
                    borderRadius="xl"
                  />
                </Text>
                <Text mt={5}>07.04.2002 - CACIB Nürnberg V 3</Text>
                <Text>13.10.2002 - CACIB Budweis V 1 CAC + CACIB</Text>
                <Text>08.12.2002 - CACIB Wels V 1 CAC + CACIB</Text>
                <Text>27.04.2003 - CACIB Budweis V 1 CAC + CACIB</Text>
                <Text>
                  29.06.2003 - CACIB Brünn - Mittel-Ost-Europa-Pokal V 1 CAC
                </Text>
                <Text>28.08.2003 - CACIB Innsbruck V 2 Res. CACA</Text>
                <Text>23.09.2003 - Titel -- Tschechischer Champion</Text>
                <Text>09.11.2003 - CACIB Tulln V 2 Res. CACA</Text>
                <Text>07.12.2003 - CACIB Wels V 2 Res. CACA</Text>
                <Text>09.11.2003 - CACIB Salzburg V 1 CAC + CACIB</Text>
              </Container>
            </SimpleGrid>
            <SimpleGrid columns={[1, 1, 2, 2]} gap={[4, 12]} marginTop={5}>
              <ModalImage
                small={diplom1}
                large={diplom1}
                maxW="400px"
                imageBackgroundColor="transparent"
                hideDownload={true}
                hideZoom={true}
              />
              <ModalImage
                small={diplom2}
                large={diplom2}
                maxW="400px"
                imageBackgroundColor="transparent"
                hideDownload={true}
                hideZoom={true}
              />
            </SimpleGrid>
            <Text>
              Kleiner Tipp:{' '} <a href="https://www.hundestudio-vierpfoten.de/files/Hundekekse.pdf">Hundekekse zum selberbacken</a>
            </Text>
          </Box>
        </motion.div>
      </Center>
    </Box>
  );
}
