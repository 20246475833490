import {
  Box,
  Center,
  Container,
  Flex,
  SimpleGrid,
  Spacer,
  Text,
} from '@chakra-ui/layout';
import Navbar from '../components/header/Navbar';
import { motion } from 'framer-motion';
import { Image } from '@chakra-ui/image';
import home from '../assets/images/home.png';
import ModalImage from 'react-modal-image';

export default function Impressum() {
  return (
    <Box>
      <Navbar />
      <Center>
          <Box
            maxW="1000px"
            boxShadow="xl"
            p={10}
            m={10}
            borderRadius="xl"
            bgColor="whiteAlpha.900"
          >
            <Text fontSize={24}>
              Impressum
              <Container
                height="5px"
                width="100%"
                backgroundColor="grey"
                borderRadius="xl"
              />
            </Text>
            <Center>
              <Text margin={10} fontWeight={'normal'}>
                
              </Text>
            </Center>
          </Box>
      </Center>
    </Box>
  );
}
