import {
  Box,
  Button,
  chakra,
  CloseButton,
  Flex,
  HStack,
  IconButton,
  Image,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  VisuallyHidden,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/images/logo.png';
import logoDark from '../../assets/images/logo-1024-dark.png';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { Link } from 'react-router-dom';

function Navbar() {
  const { colorMode } = useColorMode();
  const bg = useColorModeValue('white', 'bg');
  const mobileNav = useDisclosure();

  // return (
  //   <Box w="100%" textAlign="center" p={8} bgColor="whiteAlpha.900">
  //     <Center w="full">
  //       <Flex alignItems="center" maxW="1440px" w="full">
  //         <Link to="/">
  //           <Flex alignItems="center">
  //             <Image
  //               boxSize="50px"
  //               src={colorMode === 'light' ? logo : logoDark}
  //               alt="logo"
  //             />
  //             <Text ml={2} textAlign="start" fontSize="xl">
  //               Statistics
  //             </Text>
  //           </Flex>
  //         </Link>
  //         <Spacer />
  //         <Link to="/">
  //           <Text
  //             margin={3}
  //             transition="all 0.5s"
  //             _hover={{
  //               color: 'brown',
  //               transform: 'translateY(-2px)',
  //               transition: 'all 0.5s',
  //             }}
  //           >
  //             Home
  //           </Text>
  //         </Link>
  //         <Link to="/aboutme">
  //           <Text
  //             margin={3}
  //             transition="all 0.5s"
  //             _hover={{
  //               color: 'brown',
  //               transform: 'translateY(-2px)',
  //               transition: 'all 0.5s',
  //             }}
  //           >
  //             Über mich
  //           </Text>
  //         </Link>
  //         <Link to="/">
  //           <Text
  //             margin={3}
  //             transition="all 0.5s"
  //             _hover={{
  //               color: 'brown',
  //               transform: 'translateY(-2px)',
  //               transition: 'all 0.5s',
  //             }}
  //           >
  //             Leistungen
  //           </Text>
  //         </Link>
  //         <Link to="/">
  //           <Text
  //             margin={3}
  //             transition="all 0.5s"
  //             _hover={{
  //               color: 'brown',
  //               transform: 'translateY(-2px)',
  //               transition: 'all 0.5s',
  //             }}
  //           >
  //             Galerie
  //           </Text>
  //         </Link>
  //         <Link to="/">
  //           <Text
  //             margin={3}
  //             transition="all 0.5s"
  //             _hover={{
  //               color: 'brown',
  //               transform: 'translateY(-2px)',
  //               transition: 'all 0.5s',
  //             }}
  //           >
  //             Kontakt
  //           </Text>
  //         </Link>
  //         {/* <ColorModeSwitcher /> */}
  //       </Flex>
  //     </Center>
  //   </Box>
  // );
  return (
    <React.Fragment>
      <chakra.header
        w="full"
        px={{
          base: 2,
          sm: 4,
        }}
        py={0}
        bgColor="whiteAlpha.900"
      >
        <Flex alignItems="center" justifyContent="space-between" mx="auto">
          <Flex alignItems="center">
            <chakra.a
              href="/"
              title="Hunde Studio Vier Pfoten"
              display="flex"
              alignItems="center"
            >
              <Image
                maxW={{ base: '200px', md: '300px' }}
                src={logo}
                alt="logo"
              />
              <VisuallyHidden>Hunde Studio Vier Pfoten</VisuallyHidden>
            </chakra.a>
          </Flex>
          <HStack display="flex" alignItems="center" spacing={1}>
            <HStack
              spacing={1}
              mr={1}
              color="brand.500"
              display={{
                base: 'none',
                md: 'inline-flex',
              }}
            >
              <Link to="/">
                <Button variant="ghost">Home</Button>
              </Link>
              <Link to="/aboutme" exact="true">
                <Button variant="ghost">Über mich</Button>
              </Link>
              <Link to="/leistungen" exact="true">
                <Button variant="ghost">Leistungen</Button>
              </Link>
              <Link to="/lieblinge" exact="true">
                <Button variant="ghost">Lieblinge</Button>
              </Link>
              <Link to="/gallerie" exact="true">
                <Button variant="ghost">Galerie</Button>
              </Link>
              <Link to="/kontakt" exact="true">
                <Button variant="ghost">Kontakt</Button>
              </Link>
            </HStack>
            <Box
              display={{
                base: 'inline-flex',
                md: 'none',
              }}
            >
              <IconButton
                display={{
                  base: 'flex',
                  md: 'none',
                }}
                aria-label="Open menu"
                fontSize="20px"
                color="gray.800"
                _dark={{
                  color: 'inherit',
                }}
                variant="ghost"
                icon={<FontAwesomeIcon icon={faBars} />}
                onClick={mobileNav.onOpen}
              />

              <VStack
                pos="absolute"
                top={0}
                left={0}
                right={0}
                display={mobileNav.isOpen ? 'flex' : 'none'}
                flexDirection="column"
                p={2}
                pb={4}
                m={2}
                bg={bg}
                spacing={3}
                rounded="sm"
                shadow="sm"
              >
                <CloseButton
                  aria-label="Close menu"
                  onClick={mobileNav.onClose}
                />

                <Link to="/" exact="true">
                  <Button w="full" variant="ghost">
                    Home
                  </Button>
                </Link>
                <Link to="/aboutme" exact="true">
                  <Button w="full" variant="ghost">
                    Über mich
                  </Button>
                </Link>
                <Link to="/leistungen" exact="true">
                  <Button w="full" variant="ghost">
                    Leistungen
                  </Button>
                </Link>
                <Link to="/lieblinge" exact="true">
                  <Button w="full" variant="ghost">
                    Lieblinge
                  </Button>
                </Link>
                <Link to="/gallerie" exact="true">
                  <Button w="full" variant="ghost">
                    Galerie
                  </Button>
                </Link>
                <Link to="/kontakte" exact="true">
                  <Button w="full" variant="ghost">
                    Kontakt
                  </Button>
                </Link>
              </VStack>
            </Box>
          </HStack>
        </Flex>
      </chakra.header>
    </React.Fragment>
  );
}

export default Navbar;
